import React, { Component } from 'react'
import Layout from '../components/layout'

import SEO from '../components/seo'

import { MDBRow, MDBCol } from 'mdbreact'

export default () => {
  return (
    <>
      <Layout>
        <SEO
          title="Nominera"
          keywords={[
            `gatsby`,
            `MDBReact`,
            `react`,
            `Material Design For Bootstrap`,
          ]}
        />
        {/*<Carousel />
          <Intro />*/}
        <main>
          {/*<AboutSection />*/}
          <section id="cardSection">
            <div>
              {/*
                <Lightbox
                  image1={
                    post.frontmatter.image.childImageSharp.fluid.originalImg
                  }
                />
                */}

              <MDBRow>
                <MDBCol />

                <MDBCol size="4">
                  <h1>Nominera ert kontor</h1>
                  <p>Tyvärr är nomineringen stängd för i år.</p>
                </MDBCol>
                <MDBCol />
              </MDBRow>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}
